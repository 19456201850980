<template>
  <q-dialog v-model="isShow" persistent>
    <q-card class="add-entity add-entity-template">
      <q-btn icon="close" @click="closeModal" flat round dense v-close-popup />

      <q-card-section>
        <div class="title">Ajouter une entité</div>
      </q-card-section>

      <q-card-section>
        <div class="form-container">
          <q-form @submit="onSubmit" class="q-gutter-md">
            <div class="radio-container">
              <div class="label">
                Type d'entité
              </div>
              <div class="radio-wrapper">
                <q-radio v-model="type" val="COMPANY" label="Personne morale" />

                <q-radio v-model="type" val="PERSON" label="Personne physique" />
              </div>
            </div>

            <div class="bottom-buttons">
              <q-btn label="Annuler" class="btn-cancel" @click="closeModal" />

              <q-btn
                label="Suivant"
                class="btn-see"
                type="submit"
                icon-right="trending_flat"
              >
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'AddEntityModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      type: 'COMPANY'
    }
  },
  computed: {},
  methods: {
    ...mapActions('templates', ['addNewEntity']),
    closeModal() {
      this.$emit('closeModal')
    },
    onSubmit() {
      this.addNewEntity(this.type)
      this.$emit('middle-open-panel')
      this.$emit('closeModal')
    }
  }
}
</script>
