<template>
  <ejs-spreadsheet
    id="container"
    ref="spreadsheet"
    :openUrl="openUrl"
    :actionComplete="actionComplete"
    @beforeSelect="beforeSelect"
    :isProtected="is_protected"
    @click.native="trackStateFocus"
    @actionComplete="actionComplete"
  />
</template>

<script>
import Vue from 'vue'

import {
  SpreadsheetPlugin
  // ProtectSettingsModel
} from '@syncfusion/ej2-vue-spreadsheet'
import { mapActions, mapGetters } from 'vuex'

import {
  L10n,
  setCulture,
  setCurrencyCode,
  loadCldr
} from '@syncfusion/ej2-base'

loadCldr(
  require('./syncfusionL10n/currencies.json'),
  require('./syncfusionL10n/numbers.json'),
  require('./syncfusionL10n/ca-gregorian.json'),
  require('./syncfusionL10n/timeZoneNames.json'),
  require('./syncfusionL10n/numberingSystems.json')
)

// import * as currencies from './syncfusionL10n/currencies.json'
// import * as numbers from './syncfusionL10n/numbers.json'
// import * as ca from './syncfusionL10n/ca-gregorian.json'
// import * as timeZoneNames from './syncfusionL10n/timeZoneNames.json'
// import * as numberingSystems from './syncfusionL10n/numberingSystems.json'

// loadCldr(currencies, numbers, ca, timeZoneNames, numberingSystems)

Vue.use(SpreadsheetPlugin)

setCulture('fr')
setCurrencyCode('EUR')

L10n.load({
  fr: {
    spreadsheet: {
      InsertingEmptyValue: "La valeur de référence n'est pas valide.",
      FindValue: 'Trouver de la valeur',
      ReplaceValue: 'Remplacer la valeur',
      FindReplaceTooltip: 'Rechercher et remplacer',
      ByRow: ' Par lignes',
      ByColumn: 'Par colonnes',
      MatchExactCellElements:
        'Faire correspondre le contenu exact des cellules',
      EntercellAddress: "Entrez l'adresse de la cellule",
      FindAndReplace: 'Trouver et remplacer',
      ReplaceAllEnd: ' matches remplacés par',
      FindNextBtn: 'Find Next',
      FindPreviousBtn: 'Trouver précédent',
      ReplaceBtn: 'Remplacer',
      ReplaceAllBtn: 'Remplace tout',
      GotoHeader: 'Aller à',
      GotoSpecialHeader: 'Aller à spécial',
      Sheet: 'Feuille',
      SearchWithin: 'Rechercher dans',
      SearchBy: 'Recherché par',
      Reference: 'Référence',
      Workbook: 'Classeur',
      NoElements:
        "Nous n'avons pas pu trouver ce que vous cherchiez. Cliquez sur les options pour plus de recherche",
      FindWhat: 'Trouver quoi',
      ReplaceWith: 'Remplacer par',
      EnterValue: 'Entrez une valeur',
      Cut: 'Couper',
      Copy: 'Copie',
      Paste: 'Pâte',
      PasteSpecial: 'Collage spécial',
      All: 'Tout',
      Values: 'Valeurs',
      Formats: 'Les formats',
      Font: 'Police de caractère',
      FontSize: 'Taille de police',
      Bold: 'Audacieux',
      Italic: 'Italique',
      Underline: 'Souligner',
      Strikethrough: 'Barré',
      TextColor: 'Couleur du texte',
      FillColor: 'La couleur de remplissage',
      HorizontalAlignment: 'Alignement horizontal',
      AlignLeft: 'Alignez à gauche',
      AlignCenter: 'Centre',
      AlignRight: 'Aligner à droite',
      VerticalAlignment: 'Alignement vertical',
      AlignTop: 'Aligner en haut',
      AlignMiddle: 'Aligner au milieu',
      AlignBottom: 'Aligner en bas',
      MergeCells: 'Fusionner les cellules',
      MergeAll: 'Tout fusionner',
      MergeHorizontally: 'Fusionner horizontalement',
      MergeVertically: 'Fusionner verticalement',
      Unmerge: 'Unmerge',
      UnmergeCells: 'Dissocier les cellules',
      SelectMergeType: 'Sélectionnez le type de fusion',
      MergeCellsAlert:
        'La fusion de cellules ne conservera que la valeur en haut à gauche (la plus haute). Fusionner quand même ?',
      PasteMergeAlert:
        'Nous ne pouvons pas faire cela à une cellule de fusion.',
      Borders: 'Les frontières',
      TopBorders: 'Frontières supérieures',
      LeftBorders: 'Frontières gauches',
      RightBorders: 'Frontières droites',
      BottomBorders: 'Bordures inférieures',
      AllBorders: 'Toutes les frontières',
      HorizontalBorders: 'Bordures horizontales',
      VerticalBorders: 'Bordures verticales',
      OutsideBorders: 'Frontières extérieures',
      InsideBorders: 'Inside Borders',
      NoBorders: 'Pas de frontières',
      BorderColor: 'Couleur de la bordure',
      BorderStyle: 'Style de bordure',
      InsertFunction: 'Insérer une fonction',
      Insert: 'Insérer',
      Delete: 'Supprimer',
      Rename: 'Renommer',
      Hide: 'Cacher',
      Unhide: 'Afficher',
      NameBox: 'Boîte de nom',
      ShowHeaders: 'Afficher les en-têtes',
      HideHeaders: 'Masquer les en-têtes',
      ShowGridLines: 'Afficher le quadrillage',
      HideGridLines: 'Masquer le quadrillage',
      AddSheet: 'Ajouter une feuille',
      ListAllSheets: 'Liste de toutes les feuilles',
      FullScreen: 'Plein écran',
      CollapseToolbar: "Réduire la barre d'outils",
      ExpandToolbar: "Développer la barre d'outils",
      CollapseFormulaBar: 'Réduire la barre de formule',
      ExpandFormulaBar: 'Développer la barre de formule',
      File: 'Fichier',
      Home: 'Accueil',
      Formulas: 'Formules',
      View: 'Vue',
      New: 'Nouveau',
      Open: 'Ouvert',
      SaveAs: 'Enregistrer sous',
      ExcelXlsx: 'Microsoft Excel',
      ExcelXls: 'Microsoft Excel 97-2003',
      CSV: 'Valeurs séparées par des virgules',
      FormulaBar: 'Barre de formule',
      Sort: 'Trier',
      SortAscending: 'Ascendant',
      SortDescending: 'Descendant',
      CustomSort: 'Tri personnalisé',
      AddColumn: 'Ajouter une colonne',
      ContainsHeader: 'Les données contiennent un en-tête',
      CaseSensitive: 'Sensible aux majuscules et minuscules',
      SortBy: 'Trier par',
      ThenBy: 'Puis par',
      SelectAColumn: 'Sélectionnez une colonne',
      SortEmptyFieldError:
        'Tous les critères de tri doivent avoir une colonne spécifiée. Vérifiez les critères de tri sélectionnés et réessayez.',
      SortDuplicateFieldError:
        ' est trié par valeurs plusieurs fois. Supprimez les critères de tri en double et réessayez.',
      SortOutOfRangeError:
        'Sélectionnez une cellule ou une plage dans la plage utilisée et réessayez.',
      HideRow: 'Masquer la ligne',
      HideRows: 'Masquer les lignes',
      UnHideRows: 'Afficher les lignes',
      HideColumn: 'Masquer la colonne',
      HideColumns: 'Masquer les colonnes',
      UnHideColumns: 'Afficher les colonnes',
      InsertRow: 'Insérer une ligne',
      InsertRows: 'Insérer des lignes',
      Above: 'Au dessus de',
      Below: 'Au dessous de',
      InsertColumn: 'Insérer une colonne',
      InsertColumns: 'Insérer des colonnes',
      Before: 'Avant',
      After: 'Après',
      DeleteRow: 'Supprimer la ligne',
      DeleteRows: 'Supprimer des lignes',
      DeleteColumn: 'Supprimer la colonne',
      DeleteColumns: 'Supprimer les colonnes',
      Ok: 'OK',
      Close: 'Fermer',
      Cancel: 'Annuler',
      Apply: 'Appliquer',
      MoreColors: 'Plus de couleurs',
      StandardColors: 'Couleurs standard',
      // General: "Général",
      // Number: "Nombre",
      // Currency: "Devise",
      // Accounting: "Comptabilité",
      // ShortDate: "Date courte",
      // LongDate: "Date longue",
      // Time: "Temps",
      // Percentage: "Pourcentage",
      // Fraction: "Fraction",
      // Scientific: "Scientifique",
      // Text: "Texte",
      NumberFormat: 'Format de nombre',
      MobileFormulaBarPlaceHolder: 'Entrez une valeur ou une formule',
      PasteAlert:
        'Vous ne pouvez pas le coller ici, car la zone de copie et la zone de collage ne sont pas de la même taille. Veuillez essayer de coller dans une plage différente.',
      DestroyAlert:
        "Voulez-vous vraiment détruire le classeur actuel sans l'enregistrer et créer un nouveau classeur ?",
      SheetRenameInvalidAlert:
        'Le nom de la feuille contient un caractère non valide.',
      SheetRenameEmptyAlert: 'Le nom de la feuille ne peut pas être vide.',
      SheetRenameAlreadyExistsAlert:
        'Le nom de la feuille existe déjà. Veuillez saisir un autre nom.',
      DeleteSheetAlert: 'Voulez-vous vraiment supprimer cette feuille ?',
      DeleteSingleLastSheetAlert:
        'Un classeur doit contenir au moins une feuille de calcul visible.',
      PickACategory: 'Choisissez une catégorie',
      Description: 'La description',
      UnsupportedFile: 'Fichier non supporté',
      InvalidUrl: 'URL invalide',
      SUM: 'Ajoute une série de nombres et / ou de cellules.',
      SUMIF: 'Ajoute les cellules en fonction de la condition spécifiée.',
      SUMIFS: 'Ajoute les cellules en fonction des conditions spécifiées.',
      ABS: "Renvoie la valeur d'un nombre sans son signe.",
      RAND: 'Renvoie un nombre aléatoire compris entre 0 et 1.',
      RANDBETWEEN:
        'Renvoie un entier aléatoire basé sur des valeurs spécifiées.',
      FLOOR:
        "Arrondit un nombre au multiple le plus proche d'un facteur donné.",
      CEILING:
        "Arrondit un nombre au multiple le plus proche d'un facteur donné.",
      PRODUCT: 'Multiplie une série de nombres et / ou de cellules.',
      AVERAGE:
        'Calcule la moyenne de la série de nombres et / ou de cellules hors texte.',
      AVERAGEIF:
        'Calcule la moyenne des cellules en fonction du critère spécifié.',
      AVERAGEIFS:
        'Calcule la moyenne des cellules en fonction des conditions spécifiées.',
      AVERAGEA:
        'Calcule la moyenne des cellules évaluant VRAI comme 1, texte et FAUX comme 0.',
      COUNT:
        'Compte les cellules qui contiennent des valeurs numériques dans une plage.',
      COUNTIF: 'Compte les cellules en fonction de la condition spécifiée.',
      COUNTIFS: 'Compte les cellules en fonction des conditions spécifiées.',
      COUNTA: 'Compte les cellules qui contiennent des valeurs dans une plage.',
      MIN: 'Renvoie le plus petit nombre des arguments donnés.',
      MAX: "Renvoie le plus grand nombre d'arguments donnés.",
      DATE:
        "Renvoie la date en fonction de l'année, du mois et du jour donnés.",
      DAY: 'Renvoie le jour à partir de la date donnée.',
      DAYS: 'Renvoie le nombre de jours entre deux dates.',
      IF: "Renvoie une valeur basée sur l'expression donnée.",
      IFS: 'Renvoie une valeur basée sur les expressions multiples données.',
      CalculateAND:
        'Renvoie TRUE si tous les arguments sont TRUE, sinon renvoie FALSE.',
      CalculateOR:
        "Renvoie VRAI si l'un des arguments est VRAI, sinon renvoie FAUX.",
      IFERROR:
        "Renvoie une valeur si aucune erreur n'a été trouvée sinon, elle renverra la valeur spécifiée.",
      CHOOSE:
        "Renvoie une valeur de la liste de valeurs, basée sur le numéro d'index.",
      INDEX:
        'Renvoie une valeur de la cellule dans une plage donnée en fonction du numéro de ligne et de colonne.',
      FIND:
        "Renvoie la position d'une chaîne dans une autre chaîne, sensible à la casse",
      CONCATENATE: 'Combine deux chaînes ou plus ensemble.',
      CONCAT: 'Concatène une liste ou une plage de chaînes de texte.',
      SUBTOTAL:
        "Renvoie le sous-total d'une plage à l'aide du numéro de fonction donné.",
      RADIANS: 'Convertit les degrés en radians.',
      MATCH:
        "Renvoie la position relative d'une valeur spécifiée dans une plage donnée.",
      SLOPE:
        'Renvoie la pente de la ligne à partir de la régression linéaire des points de données.',
      INTERCEPT:
        "Calcule le point de la ligne d'interception Y via une régression linéaire.",
      DefineNameExists: 'Ce nom existe déjà, essayez un autre nom.',
      CircularReference:
        "Lorsqu'une formule fait référence à une ou plusieurs références circulaires, cela peut entraîner un calcul incorrect.",
      ShowRowsWhere: 'Afficher les lignes où:',
      OR: 'OU',
      AND: 'ET',
      CustomFilterDatePlaceHolder: 'Choisissez une date',
      CustomFilterPlaceHolder: 'Entrez la valeur',
      CustomFilter: 'Filtre personnalisé',
      Between: 'Entre',
      MatchCase: 'Cas de correspondance',
      DateTimeFilter: 'Filtres DateTime',
      Undo: 'annuler',
      Redo: 'Refaire',
      ClearAllFilter: 'Clair',
      ReapplyFilter: 'Réappliquer',
      DateFilter: 'Filtres de date',
      TextFilter: 'Filtres de texte',
      NumberFilter: 'Filtres numériques',
      ClearFilter: 'Effacer le filtre',
      NoResult: 'Aucun résultat',
      FilterFalse: 'Faux',
      FilterTrue: 'Vrai',
      Blanks: 'Blancs',
      SelectAll: 'Tout sélectionner',
      GreaterThanOrEqual: 'Meilleur que ou égal',
      GreaterThan: 'Plus grand que',
      LessThanOrEqual: 'Inférieur ou égal',
      LessThan: 'Moins que',
      NotEqual: 'Inégal',
      Equal: 'Égal',
      Contains: 'Contient',
      EndsWith: 'Se termine par',
      StartsWith: 'Commence avec',
      ClearButton: 'Clair',
      FilterButton: 'Filtre',
      CancelButton: 'Annuler',
      OKButton: "D'accord",
      Search: 'Chercher',
      DataValidation: 'La validation des données',
      CLEARALL: 'TOUT EFFACER',
      APPLY: 'APPLIQUER',
      CellRange: 'Gamme de cellules',
      Allow: 'Autoriser',
      Data: 'Les données',
      Minimum: 'Le minimum',
      Maximum: 'Maximum',
      IgnoreBlank: 'Ignorer le blanc',
      WholeNumber: 'Nombre entier',
      Decimal: 'Décimal',
      Date: 'Date',
      TextLength: 'Longueur du texte',
      List: 'liste',
      NotBetween: 'Pas entre',
      EqualTo: 'Égal à',
      NotEqualTo: 'Pas égal à',
      Greaterthan: 'Plus grand que',
      Lessthan: 'Moins que',
      GreaterThanOrEqualTo: 'Plus grand ou égal à',
      LessThanOrEqualTo: 'Inférieur ou égal à',
      InCellDropDown: 'Liste déroulante dans la cellule',
      Sources: 'Sources',
      Value: 'Valeur',
      Retry: 'Réessayez',
      DialogError:
        'La source de la liste doit être une référence à une seule ligne ou colonne.',
      ValidationError:
        'Cette valeur ne correspond pas aux restrictions de validation des données définies pour la cellule.',
      ListLengthError:
        "Les valeurs de la liste ne permettent que jusqu'à 256 caractères",
      ProtectSheet: 'Feuille de protection',
      UnprotectSheet: 'Déprotéger la feuille',
      SelectCells: 'Sélectionnez les cellules',
      FormatCells: 'Formater les cellules',
      FormatRows: 'Mettre en forme les lignes',
      'Format Columns': 'Format des colonnes',
      InsertLinks: 'Insérer des liens',
      ProtectContent: 'Protéger le contenu des cellules verrouillées',
      ProtectAllowUser:
        'Autorisez tous les utilisateurs de cette feuille de calcul à:',
      EditAlert:
        "Vous ne pouvez pas modifier un document excel après l'avoir crée depuis un template.",
      ClearValidation: 'Validation claire',
      ISNUMBER:
        'Renvoie true lorsque la valeur est analysée en tant que valeur numérique.',
      Round: 'Arrondit un nombre à un nombre spécifié de chiffres.',
      GEOMEAN:
        "Renvoie la moyenne géométrique d'un tableau ou d'une plage de données positives.",
      POWER: "Renvoie le résultat d'un nombre élevé à la puissance",
      LOG: "Renvoie le logarithme d'un nombre à la base que vous spécifiez.",
      TRUNC:
        "Renvoie la valeur tronquée d'un nombre à un nombre spécifié de décimales.",
      EXP: 'Renvoie e élevé à la puissance du nombre donné.',
      HighlightCellsRules: 'Mettre en évidence les règles des cellules',
      CFEqualTo: 'Égal à',
      TextThatContains: 'Texte qui contient',
      ADateOccuring: 'Une date survenant',
      DuplicateValues: 'Dupliquer les valeurs',
      TopBottomRules: 'Règles haut / bas',
      Top10Items: 'Top 10 des articles',
      Top10: 'Top 10',
      Bottom10Items: '10 derniers articles',
      Bottom10: '10 bas',
      AboveAverage: 'Au dessus de la moyenne',
      BelowAverage: 'Sous la moyenne',
      FormatCellsGreaterThan: 'Mettre en forme des cellules PLUS GRANDES QUE:',
      FormatCellsLessThan: 'Mettre en forme les cellules inférieures à:',
      FormatCellsBetween: 'Mettre en forme les cellules qui sont ENTRE:',
      FormatCellsEqualTo: 'Mettre en forme les cellules ÉGALES À:',
      FormatCellsThatContainTheText:
        'Mettre en forme les cellules contenant le texte:',
      FormatCellsThatContainADateOccurring:
        'Mettre en forme les cellules contenant une date:',
      FormatCellsDuplicate: 'Mettre en forme les cellules qui contiennent:',
      FormatCellsTop:
        'Mettre en forme les cellules qui se classent dans le TOP:',
      FormatCellsBottom:
        'Mettez en forme les cellules qui se classent dans le BAS:',
      FormatCellsAbove:
        'Mettre en forme les cellules supérieures à la moyenne:',
      FormatCellsBelow:
        'Mettre en forme les cellules EN-DESSOUS DE LA MOYENNE:',
      With: 'avec',
      DataBars: 'Barres de données',
      ColorScales: 'Échelles de couleurs',
      IconSets: "Jeux d'icônes",
      ClearRules: 'Effacer les règles',
      SelectedCells: 'Effacer les règles des cellules sélectionnées',
      EntireSheet: 'Effacer les règles de la feuille entière',
      LightRedFillWithDarkRedText:
        'Remplissage rouge clair avec texte rouge foncé',
      YellowFillWithDarkYellowText: 'Remplissage jaune avec texte jaune foncé',
      GreenFillWithDarkGreenText: 'Remplissage vert avec texte vert foncé',
      RedFill: 'Remplissage rouge',
      RedText: 'Texte rouge',
      Duplicate: 'Dupliquer',
      Unique: 'Unique',
      And: 'et',
      WebPage: 'PAGE WEB',
      ThisDocument: 'CE DOCUMENT',
      DisplayText: 'Afficher le texte',
      Url: 'URL',
      CellReference: 'Référence de cellule',
      DefinedNames: 'Noms définis',
      EnterTheTextToDisplay: 'Entrez le texte à afficher',
      EnterTheUrl: "Entrez l'URL",
      INT: "Renvoie un nombre à l'entier le plus proche.",
      SUMPRODUCT: 'Renvoie la somme du produit de plages de tableaux données.',
      TODAY: 'Renvoie la date actuelle sous forme de valeur de date.',
      ROUNDUP: 'Arrondit un nombre à partir de zéro.',
      Replace: 'Remplacer...',
      Find: 'Trouver et remplacer...',
      Goto: 'Aller à...',
      GotoSpecial: 'Aller à Spécial ...',
      Link: 'Lien',
      SpreadsheetHyperlink: 'Feuille de calculHyperlink',
      Hyperlink: 'Hyperlien',
      EditHyperlink: "Modifier l'hyperlien",
      OpenHyperlink: 'Ouvrir le lien hypertexte',
      RemoveHyperlink: "Supprimer l'hyperlien",
      InsertLink: 'Insérer un lien',
      EditLink: 'Modifier le lien',
      WrapText: 'Envelopper le texte',
      Update: 'Mettre à jour',
      SortAndFilter: 'Trier et filtrer',
      Filter: 'Filtre',
      FilterCellValue: 'Filtrer par valeur de la cellule sélectionnée',
      FilterOutOfRangeError:
        'Sélectionnez une cellule ou une plage dans la plage utilisée et réessayez.',
      ClearFilterFrom: 'Effacer le filtre de',
      LN: "Renvoie le logarithme naturel d'un nombre.",
      DefineNameInValid: "Le nom que vous avez entré n'est pas valide.",
      LockCells: 'Verrouiller les cellules',
      EmptyError: 'Vous devez saisir une valeur',
      ClearHighlight: 'Effacer la surbrillance',
      HighlightInvalidData: 'Mettre en évidence les données non valides',
      Clear: 'Clair',
      ClearContents: 'Effacer le contenu',
      ClearAll: 'Tout effacer',
      ClearFormats: 'Effacer les formats',
      ClearHyperlinks: 'Effacer les hyperliens',
      Image: 'Image',
      ConditionalFormatting: 'Mise en forme conditionnelle',
      BlueDataBar: 'Barre de données bleue',
      GreenDataBar: 'Barre de données verte',
      RedDataBar: 'Barre de données rouge',
      OrangeDataBar: 'Barre de données orange',
      LightblueDataBar: 'Barre de données bleu clair',
      PurpleDataBar: 'Barre de données violette',
      GYRColorScale: 'Échelle de couleur verte - jaune - rouge',
      RYGColorScale: 'Échelle de couleur rouge - jaune - verte',
      GWRColorScale: 'Échelle de couleur verte - blanche - rouge',
      RWGColorScale: 'Échelle de couleur rouge - blanc - vert',
      BWRColorScale: 'Échelle de couleur bleu - blanc - rouge',
      RWBColorScale: 'Échelle de couleur rouge - blanc - bleu',
      WRColorScale: 'Blanc - Échelle de couleur rouge',
      RWColorScale: 'Rouge - Échelle de couleur blanche',
      GWColorScale: 'Échelle de couleur verte - blanche',
      WGColorScale: 'Échelle de couleur blanche - verte',
      GYColorScale: 'Échelle de couleur verte - jaune',
      YGColorScale: 'Échelle de couleur jaune - verte',
      ThreeArrowsColor: '3 flèches (colorées)',
      ThreeArrowsGray: '3 flèches (grises)',
      ThreeTriangles: '3 triangles',
      FourArrowsColor: '4 flèches (grises)',
      FourArrowsGray: '4 flèches (colorées)',
      FiveArrowsColor: '5 flèches (grises)',
      FiveArrowsGray: '5 flèches (colorées)',
      ThreeTrafficLights1: '3 feux de signalisation (non garnis)',
      ThreeTrafficLights2: '3 feux de signalisation (cerclés)',
      ThreeSigns: '3 signes',
      FourTrafficLights: '4 feux de signalisation',
      RedToBlack: 'Du rouge au noir',
      ThreeSymbols1: '3 symboles (cerclés)',
      ThreeSymbols2: '3 symboles (non cerclés)',
      ThreeFlags: '3 drapeaux',
      ThreeStars: '3 étoiles',
      FourRatings: '4 évaluations',
      FiveQuarters: '5 trimestres',
      FiveRatings: '5 évaluations',
      FiveBoxes: '5 boîtes',
      Chart: 'Graphique',
      Column: 'Colonne',
      Bar: 'Bar',
      Area: 'Région',
      Pie: 'Tarte',
      Doughnut: 'Donut',
      PieAndDoughnut: 'Tarte / Donut',
      Line: 'Ligne',
      Radar: 'Radar',
      Scatter: 'Dispersion',
      ChartDesign: 'Conception graphique',
      ClusteredColumn: 'Colonne groupée',
      StackedColumn: 'Colonne empilée',
      StackedColumn100: 'Colonne 100% empilée',
      ClusteredBar: 'Barre groupée',
      StackedBar: 'Barre empilée',
      StackedBar100: 'Barre 100% empilée',
      StackedArea: 'Zone empilée',
      StackedArea100: 'Zone 100% empilée',
      StackedLine: 'Ligne empilée',
      StackedLine100: 'Ligne 100% empilée',
      SelectBorderOption: "Sélectionnez l'option de bordure",
      ok: 'Valider'
    }
  }
})

export default {
  name: 'Spreadsheet',
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    enableToolbar: {
      type: Boolean,
      default: false
    },
    getData: {
      type: Boolean,
      default: false
    },
    getDataMailmerge: {
      type: Boolean,
      default: false
    },
    entityElement: {
      type: Object,
      default: () => {}
    },
    entityElementDocument: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    var is_protected = false
    if (this.file.reportId != undefined) {
      is_protected = true
    }

    return {
      cell: null,
      is_protected: is_protected,
      openUrl:
        'https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open',
      saveUrl:
        'https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/save'
    }
  },
  computed: {
    ...mapGetters('templates', ['getS3url', 'getPlacedStatus']),
    ...mapGetters('documents', ['getS3urlMailmerge'])
  },
  async mounted() {
    if (this.file.result) {
      if (this.file && this.file.modelStatus === 'FIRST_UPLOAD') {
        const result = await fetch(this.file.result.url, {
          method: 'GET'
        }).then(res => res.blob())
        const file = new File([result], this.file.name)
        await this.$refs.spreadsheet.open({ file })
      } else {
        const result = await fetch(this.file.result.url, {
          method: 'GET'
        }).then(res => res.json())
        await this.$refs.spreadsheet.openFromJson({ file: result.jsonObject })
      }

      // Disable menu item that let user lock the spreadsheet
      try {
        // this.$refs.spreadsheet.hideRibbonTabs(['Les données']);
        // this.$refs.spreadsheet.hideFileMenuItems(['Nouveau', 'Ouvert']);
        // this.$refs.spreadsheet.enableFileMenuItems([`${this.$refs.spreadsheet.ej2Instances.element.id}_New`], false, true);
        // this.$refs.spreadsheet.hideRibbonTabs(['Fichier']);
      } catch {
        console.log('Error disabling menu')
      }

      if (this.file.reportId != undefined) {
        this.setMailmergeStatus('ONGOING')
        const response = await this.$refs.spreadsheet.saveAsJson()
        this.$emit(
          'actualDataMailmerge',
          JSON.stringify(response)
        )
      }

      // If the user is editing a document we lock the spreasheet
      if (this.file.reportId != undefined) {
        let protectSetting = {
          selectCells: true,
          formatCells: false,
          formatRows: false,
          formatColumns: false,
          insertLink: false
        }
        this.$refs.spreadsheet.protectSheet(0, protectSetting)
      }

      // if (this.file.reportId != undefined) {
      //   try {
      //     console.log('Locking sheet ...')
      //     console.log('Locking sheet - DONE')
      //   } catch {
      //     console.log('Error locking')
      //     console.log()
      //   }
      // }
    }
  },
  methods: {
    ...mapActions('templates', ['setFocusedDocument']),
    ...mapActions('documents', ['setMailmergeStatus']),
    isDocument: function() {
      if (this.file.reportId != undefined) {
        return true
      } else {
        return false
      }
    },
    actionComplete: function(args) {
      if (this.file && this.file.modelStatus === 'FIRST_UPLOAD') {
        this.$emit('getFileName', this.file.name, false)
      }

      // To protect the switched sheets if in document
      if (this.file.reportId != undefined) {
        var spreadsheet = this.$refs.spreadsheet
        let protectSetting = {
          selectCells: true,
          formatCells: false,
          formatRows: false,
          formatColumns: false,
          insertLink: false
        }

        if (args.action === 'gotoSheet') {
          console.log(args.eventArgs.currentSheetIndex)
          spreadsheet.protectSheet(
            args.eventArgs.currentSheetIndex,
            protectSetting
          )
        }
      }
    },
    trackStateFocus() {
      if (this.getPlacedStatus) {
        this.setFocusedDocument(true)
      } else {
        this.setFocusedDocument(false)
      }
    },
    beforeSelect(args) {
      this.cell = args.range
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    async updateDocument(val) {
      const s3Url = val.s3Url
      var spreadsheet = this.$refs.spreadsheet

      const result = await fetch(s3Url, {
        method: 'GET'
      }).then(res => res.json())

      spreadsheet.refresh()
      await this.$refs.spreadsheet.openFromJson({ file: result.jsonObject })

      let protectSetting = {
        selectCells: true,
        formatCells: false,
        formatRows: false,
        formatColumns: false,
        insertLink: false
      }
      this.$refs.spreadsheet.protectSheet(0, protectSetting)

      this.setMailmergeStatus('DONE')
    },
    async saveActualData() {
      try {
        this.$refs.spreadsheet.endEdit()

        this.$emit('activeLoading')
        const response = await this.$refs.spreadsheet.saveAsJson()

        const formData = new FormData()
        formData.append(
          'JSONData',
          JSON.stringify(response.jsonObject.Workbook)
        )
        formData.append('saveType', 'Xlsx')
        formData.append('url', this.saveUrl)
        formData.append('fileName', this.file.name)

        await fetch(this.saveUrl, {
          method: 'POST',
          body: formData
        }).then(res => res.blob())

        this.$emit('actualData', JSON.stringify(response))
        this.$emit('deactivateLoading')
      } catch (e) {
        this.showNotify('Une erreur est survenue')
      }
    }
  },
  watch: {
    getS3urlMailmerge: {
      handler(val) {
        if (val.type === 'EXCEL') {
          this.updateDocument(val)
        }
      },
      deep: true
    },
    getS3url: {
      handler(val) {
        if (val.type === 'EXCEL') {
          this.updateDocument(val)
        }
      },
      deep: true
    },
    getData: async function(status) {
      if (status) {
        await this.saveActualData()
      }
    },
    entityElement: function(data) {
      if (data) {
        const elementCode = data.element.code || data.element.dataCode
        const elementText = data.element.text || data.element.dataLabel
        let label = `${data.entity.name}--${elementText}`
        let code = `${data.entity.name}--${elementCode}`
        if (data.element.dataYear !== '0') {
          label = `${data.entity.name}--${elementText}`
          code = `${data.entity.name}--${elementCode}__${data.element.dataYear}`
        }

        const cell = this.cell ? this.cell.split(':') : null
        const position = cell.length ? cell[0] : ''
        this.$refs.spreadsheet.updateCell({ value: `%${code}` }, position)

        this.$emit('placeSelected', { data, code, label, position })
      }
    },
    entityElementDocument: function(data) {
      if (data) {
        const elementCode = data.element.code || data.element.dataCode
        const elementText = data.element.text || data.element.dataLabel
        let label = `${data.entity.name}--${elementText}`
        let code = `${data.entity.name}--${elementCode}`
        if (data.element.dataYear !== '0') {
          label = `${data.entity.name}--${elementText}`
          code = `${data.entity.name}--${elementCode}__${data.element.dataYear}`
        }

        const cell = this.cell ? this.cell.split(':') : null
        const position = cell.length ? cell[0] : ''

        if (this.file.reportId != undefined) {
          this.$refs.spreadsheet.unprotectSheet()
        }

        this.$refs.spreadsheet.updateCell({ value: `${data.element.dataValue}` }, position)

        if (this.file.reportId != undefined) {
          let protectSetting = {
            selectCells: true,
            formatCells: false,
            formatRows: false,
            formatColumns: false,
            insertLink: false
          }
          this.$refs.spreadsheet.protectSheet(0, protectSetting)
        }

        this.$emit('placeSelected', { data, code, label, position })
      }
    },
    getDataMailmerge: async function(status) {
      if (status) {
        const response = await this.$refs.spreadsheet.saveAsJson()
        this.$emit(
          'actualDataMailmerge',
          JSON.stringify(response)
        )
      }
    }
  }
}
</script>

<style scoped>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-vue-spreadsheet/styles/material.css';
</style>
