<template>
  <ejs-documenteditorcontainer
    ref="documenteditor"
    :serviceUrl="serviceUrl"
    id="container"
    :class="{ 'hide-panel': !getCurrentUser.userRights.rightModifyModel }"
    :restrictEditing="!getCurrentUser.userRights.rightModifyModel"
    :enableToolbar="enable"
    :enableWordExport="true"
    @click.native="trackStateFocus"
    @contentChange="changed"
    :toolbarItems="toolBarItems"
    locale="fr"
  />
</template>

<script>
import Vue from 'vue'
import {
  DocumentEditorContainerPlugin,
  Toolbar,
  WordExport
} from '@syncfusion/ej2-vue-documenteditor'
import { mapGetters, mapActions } from 'vuex'

import { L10n } from '@syncfusion/ej2-base'

L10n.load({
  fr: {
    documenteditorcontainer: {
      New: 'Nouveau',
      Open: 'Ouvert',
      Undo: 'annuler',
      Redo: 'Refaire',
      Image: 'Image',
      Table: 'Table',
      Link: 'Lien',
      Bookmark: 'Signet',
      'Table of Contents': 'Table des matières',
      'HEADING - - - - 1': 'RUBRIQUE - - - - 1',
      'HEADING - - - - 2': 'RUBRIQUE - - - - 2',
      'HEADING - - - - 3': 'RUBRIQUE - - - - 3',
      Header: 'Entête',
      Footer: 'Bas de page',
      'Page Setup': 'Mise en page',
      'Page Number': 'Numéro de page',
      Break: 'Pause',
      Find: 'Trouver',
      'Local Clipboard': 'Presse-papiers local',
      'Restrict Editing': "Restreindre l'édition",
      'Upload from computer': "Télécharger d'un ordinateur",
      'By URL': 'Par URL',
      'Page Break': 'Saut de page',
      'Section Break': 'Saut de section',
      'Header And Footer': 'En-tête et pied de page',
      Options: 'Les options',
      Levels: 'Les niveaux',
      'Different First Page': 'Première page différente',
      'Different header and footer for odd and even pages':
        'En-tête et pied de page différents pour les pages paires et impaires.',
      'Different Odd And Even Pages': 'Pages paires et impaires différentes',
      'Different header and footer for first page':
        'En-tête et pied de page différents pour la première page.',
      Position: 'Position',
      'Header from Top': 'En-tête du haut',
      'Footer from Bottom': 'Pied de page du bas',
      'Distance from top of the page to top of the header':
        "Distance du haut de la page au haut de l'en-tête.",
      'Distance from bottom of the page to bottom of the footer':
        'Distance du bas de la page au bas du pied de page.',
      'Aspect ratio': "Ratio d'aspect",
      W: 'W',
      H: 'H',
      Width: 'Largeur',
      Height: 'la taille',
      Text: 'Texte',
      Paragraph: 'Paragraphe',
      Fill: 'Remplir',
      'Fill color': 'La couleur de remplissage',
      'Border Style': 'Style de bordure',
      'Outside borders': 'Hors frontières',
      'All borders': 'Toutes les frontières',
      'Inside borders': 'Frontières intérieures',
      'Left border': 'Bordure gauche',
      'Inside vertical border': 'Bordure verticale intérieure',
      'Right border': 'Bordure droite',
      'Top border': 'Bordure supérieure',
      'Inside horizontal border': 'Bordure horizontale intérieure',
      'Bottom border': 'Bordure inférieure',
      'Border color': 'Couleur de la bordure',
      'Border width': 'Largeur de la bordure',
      Cell: 'Cellule',
      'Merge cells': 'Fusionner des cellules',
      'Insert Or Delete': 'Insérer / supprimer',
      'Insert columns to the left': 'Insérer des colonnes à gauche',
      'Insert columns to the right': 'Insérer des colonnes à droite',
      'Insert rows above': 'Insérer des lignes au-dessus',
      'Insert rows below': 'Insérer des lignes ci-dessous',
      'Delete rows': 'Supprimer des lignes',
      'Delete columns': 'Supprimer des colonnes',
      'Cell Margin': 'Marge cellulaire',
      Top: 'Haut',
      Bottom: 'Bas',
      Left: 'La gauche',
      Right: 'Droite',
      'Align Text': 'Aligner le texte',
      'Align top': 'Aligner le haut',
      'Align bottom': 'Aligner en bas',
      'Align center': 'Aligner le centre',
      'Number of heading or outline levels to be shown in table of contents':
        'Nombre de niveaux de titre ou de plan à afficher dans la table des matières.',
      'Show page numbers': 'Afficher les numéros de page',
      'Show page numbers in table of contents':
        'Afficher les numéros de page dans la table des matières.',
      'Right align page numbers': 'Aligner à droite les numéros de page',
      'Right align page numbers in table of contents':
        'Alignez à droite les numéros de page dans la table des matières.',
      'Use hyperlinks': 'Utilisez des hyperliens',
      'Use hyperlinks instead of page numbers':
        'Utilisez des hyperliens au lieu des numéros de page.',
      Font: 'Police de caractère',
      'Font Size': 'Taille de police',
      'Font color': 'Couleur de la police',
      'Text highlight color': 'Couleur de surbrillance du texte',
      'Clear all formatting': 'Effacer toute mise en forme',
      'Bold Tooltip': 'Gras (Ctrl + B)',
      'Italic Tooltip': 'Italique (Ctrl + I)',
      'Underline Tooltip': 'Souligné (Ctrl + U)',
      Strikethrough: 'Barré',
      'Superscript Tooltip': 'Exposant (Ctrl + Shift ++)',
      'Subscript Tooltip': 'Indice (Ctrl + =)',
      'Align left Tooltip': 'Aligner à gauche (Ctrl + L)',
      'Center Tooltip': 'Centre (Ctrl + E)',
      'Align right Tooltip': 'Aligner à droite (Ctrl + R)',
      'Justify Tooltip': 'Justifier (Ctrl + J)',
      'Decrease indent': 'Diminuer le retrait',
      'Increase indent': 'Augmenter le retrait',
      'Line spacing': 'Interligne',
      Bullets: 'Balles',
      Numbering: 'Numérotage',
      Styles: 'modes',
      'Manage Styles': 'Gérer les styles',
      Page: 'Page',
      of: 'de',
      'Fit one page': 'Ajuster une page',
      'Spell Check': 'Vérification orthographique',
      'Underline errors': 'Souligner les erreurs',
      'Fit page width': 'Ajuster la largeur de la page',
      Update: 'Mise à jour',
      Cancel: 'Annuler',
      Insert: 'Insérer',
      'No Border': 'Pas de frontière',
      'Create a new document': 'Créez un nouveau document.',
      'Open a document': 'Ouvrez un document.',
      'Undo Tooltip': 'Annule la dernière opération (Ctrl + Z).',
      'Redo Tooltip': 'Refait la dernière opération (Ctrl + Y).',
      'Insert inline picture from a file':
        "Insérez une image en ligne à partir d'un fichier.",
      'Insert a table into the document': 'Insérer un tableau dans le document',
      'Create Hyperlink':
        'Créez un lien dans votre document pour un accès rapide aux pages Web et aux fichiers (Ctrl + K).',
      'Insert a bookmark in a specific place in this document':
        'Insérez un signet à un endroit spécifique de ce document.',
      'Provide an overview of your document by adding a table of contents':
        'Donnez un aperçu de votre document en ajoutant une table des matières.',
      'Add or edit the header': "Ajoutez ou modifiez l'en-tête.",
      'Add or edit the footer': 'Ajoutez ou modifiez le pied de page.',
      'Open the page setup dialog':
        'Ouvrez la boîte de dialogue de mise en page.',
      'Add page numbers': 'Ajoutez des numéros de page.',
      'Find Text': 'Recherchez du texte dans le document (Ctrl + F).',
      'Toggle between the internal clipboard and system clipboard':
        "Basculez entre le presse-papiers interne et le presse-papiers système. </br> L'accès au presse-papiers système via un script est refusé en raison de la politique de sécurité des navigateurs. Au lieu de cela, </br> 1. Vous pouvez activer le presse-papiers interne pour couper, copier et coller dans le composant. </br> 2. Vous pouvez utiliser les raccourcis clavier (Ctrl + X, Ctrl + C et Ctrl + V) pour couper , copiez et collez avec le presse-papiers du système.",
      'Current Page Number':
        'Numéro de page actuel dans le document. Cliquez ou appuyez sur pour parcourir une page spécifique.',
      'Read only': 'Lecture seulement',
      Protections: 'Protections',
      'Error in establishing connection with web server':
        "Erreur lors de l'établissement de la connexion avec le serveur Web",
      Single: 'Célibataire',
      Double: 'Double',
      'New comment': 'Nouveau commentaire',
      Comments: 'commentaires',
      'Print layout': "Disposition d'impression",
      'Web layout': 'mise en page Web',
      'Text Form': 'Formulaire texte',
      'Check Box': 'Case à cocher',
      DropDown: 'Menu déroulant',
      'Update Fields': 'Champs de mise à jour',
      'Update cross reference fields':
        'Mettre à jour les champs de référence croisée',
      'Hide properties pane': 'Masquer le volet des propriétés',
      'Show properties pane': 'Afficher le volet des propriétés',
      'Form Fields': 'Champs de formulaire',
      'Track Changes':
        'Gardez une trace des modifications apportées au document',
      TrackChanges: 'Suivi des modifications',
      AllCaps: 'Toutes en majuscules',
      'Change case Tooltip': 'Changer de cas',
      'Insert Footnote': 'Insérer une note de bas de page',
      'Insert Endnote': 'Insérer une note de fin',
      'Footnote Tooltip': 'Insérer une note de bas de page (Alt + Ctrl + F).',
      'Endnote Tooltip': 'Insérer une note de fin (Alt + Ctrl + D).'
    }
  }
})

Vue.use(DocumentEditorContainerPlugin)

export default {
  name: 'DocumentEditor',
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    enableToolbar: {
      type: Boolean,
      default: false
    },
    getData: {
      type: Boolean,
      default: false
    },
    getDataMailmerge: {
      type: Boolean,
      default: false
    },
    entityElement: {
      type: Object,
      default: () => {}
    },
    isOpenMiddlePanel: {
      type: Boolean,
      default: false
    },
    entityElementDocument: {
      type: Object,
      default: () => {}
    },
    reportId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      serviceUrl:
        'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/',
      enable: false,
      toolBarItems: [
        'Undo',
        'Redo',
        'Separator',
        'Image',
        'Table',
        'Hyperlink',
        'TableOfContents',
        'Separator',
        'Header',
        'Footer',
        'Separator',
        'PageSetup',
        'PageNumber',
        'Break',
        'Separator',
        'InsertFootnote',
        'InsertEndnote',
        'Separator',
        'Find',
        'Separator',
        'Comments',
        'TrackChanges',
        'Separator',
        'FormFields',
        'UpdateFields'
      ],
      timeOutSet: '',
      currentPageView: 0
    }
  },
  provide: {
    DocumentEditorContainer: [Toolbar, WordExport]
  },
  computed: {
    ...mapGetters('users', ['getCurrentUser']),
    ...mapGetters('templates', ['getS3url', 'getPlacedStatus']),
    ...mapGetters('documents', ['getS3urlMailmerge'])
  },
  async mounted() {
    // Set catch event
    this.$refs.documenteditor.ej2Instances.documentEditor.viewChange = (event) => {
      this.documentViewChangeEvent(event); 
    };

    this.setMailmergeStatus('ONGOING')

    const { result } = this.file
    await this.$refs.documenteditor.ej2Instances.documentEditor.open({
      ...result
    })

    this.enable = this.getCurrentUser.userRights.rightModifyModel

    this.$refs.documenteditor.ej2Instances.documentEditor
      .saveAsBlob('Docx')
      .then(res => {
        this.$emit(
          'defaultData',
          this.$refs.documenteditor.ej2Instances.documentEditor.serialize(),
          res
        )
      })

      // Mailmerge at open
      if (this.reportId != '') {
        this.fetchMailMerge(this.reportId).then(i => {
          this.setS3Url({
            type: this.file.typeShort,
            s3Url: i.s3Url,
            s3UrlOriginal: i.s3UrlOriginal,
            name: this.file.name
          })
        })
      }
  },
  methods: {
    ...mapActions('templates', ['setFocusedDocument']),
    ...mapActions('documents', ['setMailmergeStatus']),
    ...mapActions('documents', ['fetchMailMerge', 'setS3Url']),
    trackStateFocus() {
      if (this.getPlacedStatus) {
        this.setFocusedDocument(true)
      } else {
        this.setFocusedDocument(false)
      }
    },
    async updateDocument(s3url) {
      const result = await fetch(s3url).then(res => res.json())
      this.$refs.documenteditor.ej2Instances.documentEditor.open({
        ...result
      })
      this.setMailmergeStatus('DONE')
      this.$refs.documenteditor.ej2Instances.documentEditor.scrollToPage(this.currentPageView)
    },
    documentViewChangeEvent(event) {
      this.currentPageView = event.startPage
    },
    changed() {
      this.$emit('actualDataChanged', '')
      // if (this.timeOutSet != '') {
      //   clearTimeout(this.timeOutSet)
      // }
      // let timeout_id = setTimeout(() => {
      //   this.$refs.documenteditor.ej2Instances.documentEditor
      //     .saveAsBlob('Docx')
      //     .then(res => {
      //       this.$emit(
      //         'actualDataChanged',
      //         this.$refs.documenteditor.ej2Instances.documentEditor.serialize(),
      //         res
      //       )
      //     })
      // }, 5000)
      // this.timeOutSet = timeout_id
    }
  },
  watch: {
    getS3urlMailmerge: {
      handler(val) {
        if (val.type === 'DOCX') {
          this.updateDocument(val.s3Url)
        }
      },
      deep: true
    },
    getS3url: {
      handler(val) {
        if (val.type === 'DOCX') {
          this.updateDocument(val.s3Url)
        }
      },
      deep: true
    },
    getData: async function(status) {
      if (status) {
        this.$refs.documenteditor.ej2Instances.documentEditor
          .saveAsBlob('Docx')
          .then(res => {
            this.$emit(
              'actualData',
              this.$refs.documenteditor.ej2Instances.documentEditor.serialize(),
              res
            )
          })
      }
    },
    getDataMailmerge: async function(status) {
      if (status) {
        this.$refs.documenteditor.ej2Instances.documentEditor
          .saveAsBlob('Docx')
          .then(res => {
            this.$emit(
              'actualDataMailmerge',
              this.$refs.documenteditor.ej2Instances.documentEditor.serialize(),
              res
            )
          })
      }
    },
    entityElement: function(data) {
      if (data) {
        const elementCode = data.element.code || data.element.dataCode
        const elementText = data.element.text || data.element.dataLabel
        let label = `${data.entity.name}--${elementText}`
        let code = `${data.entity.code}--${elementCode}`.toUpperCase()
        if (data.element.dataYear !== '0') {
          label = `${data.entity.name}--${elementText}`
          code = `${data.entity.code}--${elementCode}__${data.element.dataYear}`.toUpperCase()
        }

        const editor = this.$refs.documenteditor.ej2Instances.documentEditor
          .editor

        editor.insertField('MERGEFIELD ' + code + ' \\* MERGEFORMAT', label)
        this.$emit('placeSelected', {
          data,
          code,
          label,
          page: editor.documentHelper.currentPage.index + 1
        })
      }
    },
    isOpenMiddlePanel: function() {
      this.$refs.documenteditor.ej2Instances.documentEditor.resize()
    },
    entityElementDocument: function(data) {
      if (data) {
        const elementCode = data.element.code || data.element.dataCode
        const elementText = data.element.text || data.element.dataLabel
        let label = `${data.element.dataValue}`
        let code = `${data.entity.code}--${elementCode}`.toUpperCase()
        if (data.element.dataYear !== '0') {
          label = `${data.element.dataValue}`
          code = `${data.entity.code}--${elementCode}__${data.element.dataYear}`.toUpperCase()
        }
        const editor = this.$refs.documenteditor.ej2Instances.documentEditor
          .editor
        editor.insertField('MERGEFIELD ' + code + ' \\* MERGEFORMAT', label)
        // this.$emit('placeSelected', {
        //   data,
        //   code,
        //   label,
        //   page: editor.documentHelper.currentPage.index + 1
        // })
      }
    }
  }
}
</script>

<style scoped>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-vue-documenteditor/styles/material.css';

::v-deep .e-documenteditor-optionspane {
  width: 100% !important;
}
</style>
