<template>
  <q-dialog v-model="isShow" persistent>
    <q-card class="delete">
      <q-btn icon="close" @click="closeModal" flat round dense v-close-popup />

      <q-card-section>
        <div class="title">Supprimer l’entité</div>
      </q-card-section>

      <q-card-section>
        <p class="description">
          Êtes-vous sûr de vouloir supprimer l’entité {{ getEditEntity }} ?
        </p>
      </q-card-section>

      <q-card-section class="bottom-buttons">
        <q-btn label="Annuler" class="btn-cancel" @click="closeModal" />

        <q-btn label="Supprimer" class="btn-trash" @click="remove">
          <q-icon class="trash-icon" />
        </q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'DeleteModalTemplate',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    getEditEntity() {
      return this.content
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    remove() {
      this.$emit('removed')
    }
  }
}
</script>
