<template>
  <q-dialog id="dialog" v-model="isShow" persistent>
    <q-card class="delete">
      <q-btn icon="close" @click="closeModal" flat round dense v-close-popup />

      <q-card-section>
        <div class="title">{{ content.title }}</div>
      </q-card-section>

      <q-card-section>
        <p class="description">
          {{ content.description }}
        </p>
      </q-card-section>

      <q-card-section class="bottom-buttons">
        <q-btn
          label="Annuler les changements"
          class="btn-cancel"
          @click="discardChanges"
        />

        <q-btn label="Sauvegarder" class="btn-save" @click="save">
          <q-icon class="save-icon" />
        </q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'UnsavedChanges',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    discardChanges() {
      this.$emit('discardChanges')
    },
    save() {
      this.$emit('saved')
    }
  }
}
</script>
